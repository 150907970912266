<template>
    <div>
        <Header />
        <mdb-parallax style="margin-top: -13px"
            src="/img/bgs/bg3.jpg"
            height="full"
            factor="1"
            >
            <mdb-mask overlay="blue-slight" flexCenter>
                <mdb-row class="mb-5 pb-5 text-center white-text">
                <mdb-col md="12" xl="12" class="mx-auto">
                    <h1 class="display-4 font-weight-bold animated fadeInDown">Servicios</h1>
                </mdb-col>
                </mdb-row>
            </mdb-mask>
        </mdb-parallax>
        <div>
            <mdb-container>
                <mdb-row>
                    <div class="text-justify mx-2">
                        <p class="mt-5 ar-text">Nuestros servicios ofrecen alternativas sostenibles y contribuyen a la transición hacia un modelo más sustentable y consciente. Nos enorgullece combinar el conocimiento científico y la innovación en nuestros enfoques integrales, desarrollando alternativas estratégicas que no solo disminuyen el impacto ambiental, sino también promueven el desarrollo sostenible y el uso responsable de los recursos naturales como parte de nuestro compromiso con la acción climática.</p>
                    </div>
                </mdb-row>
                <mdb-row>
                    <mdb-col>
                        <section class="service-section">
                            <mdb-card class="card-image" style="background-image: url(/img/services/nbs.png)">
                                <div class="text-white text-center rgba-stylish-strong py-5 px-4">
                                    <div class="py-5">
                                    <h2 class="card-title h2 my-4 py-2 orange-text"><i class="fas fa-leaf"></i> Soluciones Basadas en la Naturaleza</h2>
                                    <mdb-btn tag="a" rounded @click.native="toggleServiceModal('sbn')" gradient="blue">Saber más</mdb-btn>
                                    </div>
                                </div>
                            </mdb-card>
                        </section>
                    </mdb-col>
                </mdb-row>
                <br class="mt-4 mb-4">
                <mdb-row>
                    <mdb-col>
                        <section class="service-section">
                            <mdb-card class="card-image" style="background-image: url(/img/services/c-action.png)">
                                <div class="text-white text-center rgba-stylish-strong py-5 px-4">
                                    <div class="py-5">
                                    <h2 class="card-title h2 my-4 py-2 orange-text"><i class="fas fa-tree"></i> Proyectos de acción climática</h2>
                                    <mdb-btn tag="a" rounded @click.native="toggleServiceModal('pac')" gradient="blue">Saber más</mdb-btn>
                                    </div>
                                </div>
                            </mdb-card>
                        </section>
                    </mdb-col>
                </mdb-row>
                <br class="mt-4 mb-4">
                <mdb-row>
                    <mdb-col>
                        <section class="service-section">
                            <mdb-card class="card-image" style="background-image: url(/img/services/ambstud.png)">
                                <div class="text-white text-center rgba-stylish-strong py-5 px-4">
                                    <div class="py-5">
                                    <h2 class="card-title h2 my-4 py-2 orange-text"><i class="fas fa-search"></i> Estudios ambientales en el sector energético</h2>
                                    <mdb-btn tag="a" rounded @click.native="toggleServiceModal('estudios')" gradient="blue">Saber más</mdb-btn>
                                    </div>
                                </div>
                            </mdb-card>
                        </section>
                    </mdb-col>
                </mdb-row>
                <br class="mt-4 mb-4">
                <mdb-row>
                    <mdb-col>
                        <section class="service-section">
                            <mdb-card class="card-image" style="background-image: url(/img/services/gra.png)">
                                <div class="text-white text-center rgba-stylish-strong py-5 px-4">
                                    <div class="py-5">
                                    <h2 class="card-title h2 my-4 py-2 orange-text"><i class="fas fa-list"></i> Gestión Regulatoria Ambiental</h2>
                                    <mdb-btn tag="a" rounded @click.native="toggleServiceModal('gestion')" gradient="blue">Saber más</mdb-btn>
                                    </div>
                                </div>
                            </mdb-card>
                        </section>
                    </mdb-col>
                </mdb-row>
                <br class="mt-4 mb-4">
                <mdb-row>
                    <mdb-col>
                        <section class="service-section">
                            <mdb-card class="card-image" style="background-image: url(/img/services/ren-tech.png)">
                                <div class="text-white text-center rgba-stylish-strong py-5 px-4">
                                    <div class="py-5">
                                    <h2 class="card-title h2 my-4 py-2 orange-text"><i class="fas fa-recycle"></i> Tecnologías renovables</h2>
                                    <mdb-btn tag="a" rounded @click.native="toggleServiceModal('tec')" gradient="blue">Saber más</mdb-btn>
                                    </div>
                                </div>
                            </mdb-card>
                        </section>
                    </mdb-col>
                </mdb-row>
            <mdb-row>
                <mdb-col md="6" offsetMd="2" class="text-center mt-5 mb-5">
                    <button @click="$store.commit('_toggleContactModal')" class="btn btn-arvore btn-block">Solicitar cotización</button>
                </mdb-col>
            </mdb-row>
            </mdb-container>
        </div>
        <mdb-modal size="lg" :show="modal" @close="toggleServiceModal('')">
            <mdb-modal-header style="background-color: #11619d; color: white;">
                <mdb-modal-title style="font-weight: bold;">{{ selected.name != undefined ? selected.name : '' }}</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
                <mdb-row>
                    <mdb-col class="text-left">
                        <p class="ar-text text-justify" v-for="parr in selected.p" :key="parr" v-html="parr"></p>
                        <br>
                    </mdb-col>   
                </mdb-row>
            </mdb-modal-body>
            <mdb-modal-footer>
                <button class="btn btn-arvore" @click="toggleServiceModal('')">Cerrar</button>
            </mdb-modal-footer>
        </mdb-modal>
        <Footer />
    </div>
</template>
<script>
    import { mdbContainer, mdbRow, mdbCol, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbCard, mdbIcon, mdbCardBody, mdbMask, mdbView, mdbBtn, mdbParallax  } from "mdbvue";
    import Header from "./Header";
    import Footer from "./Footer";

    export default {
        name: "Services",
        components: {
            mdbContainer,
            mdbRow,
            mdbCol, 
            Header,
            Footer,
            mdbModal,
            mdbModalHeader, 
            mdbModalTitle, 
            mdbModalBody, 
            mdbModalFooter,
            mdbCard,
            mdbCardBody,
            mdbIcon,
            mdbMask,
            mdbView,
            mdbBtn,
            mdbParallax
        },
        data(){
            return {
                modal: false,
                team: [
                    {
                        id: "gestion",
                        name: "Gestión regulatoria ambiental",
                        p: [
                            "La gestión regulatoria ambiental se refiere al conjunto de acciones y procesos llevados a cabo para cumplir con las regulaciones y normativas ambientales vigentes.",
                            "En Arvore le damos seguimiento a las leyes, reglamentos y políticas relacionadas con el medio ambiente, con el objetivo de garantizar el cumplimiento de los requisitos legales en términos de protección ambiental.",
                            "Dentro de los servicios que ofrece Arvore, se encuentran la obtención de permisos, la evaluación y gestión de impactos ambientales (MIA´s, EDA´s etc.), planes de manejo, entre otras."
                        ]
                    },
                    {
                        id: "sbn",
                        name: "Soluciones Basadas en la Naturaleza",
                        p: [
                           "Los Proyectos orientados en soluciones basados en la naturaleza (NBS) por sus siglas en ingles, son iniciativas que utilizan soluciones naturales y ecosistemas saludables para abordar desafíos ambientales y sociales.",
                           "Estos proyectos se enfocan en la conservación, restauración, conectividad y gestión sostenible de la biodiversidad y los ecosistemas, como bosques, humedales, ríos y áreas costeras.",
                           "Nuestra propuesta busca aprovechar los servicios ecosistémicos, como la captura de carbono, la mejora de la calidad del agua y el hábitat para la biodiversidad a fin de promover la resiliencia y la sostenibilidad.",
                           "En Arvore, pretendemos que todos nuestros proyectos sean vinculantes e integrales, ofreciendole al cliente una garantía de calidad e impacto en cada uno de los proyectos que se realizan."
                        ]
                    },
                    {
                        id: "estudios",
                        name: "Estudios ambientales en el sector energético",
                        p: [
                            "Los estudios ambientales en el sector energético son análisis y evaluaciones que se realizan para comprender, monitorear y mitigar los impactos ambientales de las actividades relacionadas con la producción, transporte, distribución y uso de energía.",
                            "Los estudios que ofrecemos en Arvore examinan aspectos como la dispersión de gases en aire, el consumo de recursos naturales, las emisiones de gases de efecto invernadero y su acumulación en matrices como suelo, agua y vegetación, el impacto en la biodiversidad y los ecosistemas, estudios de permeabilidad e infiltración y estudios en la hidrología subterránea y superficial de los contextos ambientales de producción energética.",
                            "Nuestro objetivo es garantizar una gestión sostenible de la energía y minimizar los efectos negativos en el medio ambiente."
                        ]
                    
                    },
                    {
                        id: "pac",
                        name: "Proyectos de Acción Climática",
                        p: [
                            "En Arvore nos especializamos como <b style='font-weight: 800;'>desarrolladores</b> de proyectos en la <b style='font-weight: 800;'>captura de CO2</b> y se refiere a la elaboración y ejecución de proyectos que compensen y/o capturen las emisiones de dióxido de carbono (CO2) generadas por una actividad o proceso. Esto a través de la inversión en proyectos o acciones que reduzcan o capturen CO2 equivalente en otra parte.",
                            "Los proyectos que Arvore ofrece, pueden incluir la reforestación, la conservación y restauración de bosques, la conectividad del paisaje, la ganadería y la agricultura regenerativa, entre otros. Este proceso lo realizamos en colaboracion con certificadores y verificadores internacionales a fin de incidir directamente sobre el Mercado Voluntario de Carbono y su ecosistema."
                        ]
                    },                    
                    {
                        id: "tec",
                        name: "Tecnologías renovables",
                        p: [
                            "Las tecnologías renovables son aquellas que utilizan fuentes de energía natural y sostenible, que se regeneran rápidamente y que en principio no se agotan.",
                            "En Arvore trabajamos de la mano de aliados estrategicos en el sector, impulsando y promoviendo el uso de energia fotovoltaica para diversos fines como la agricultura, iluminación urbana, transporte público, entre otros.",
                            "También diseñamos y ejecutamos proyectos que estan orientados al mejoramiento de los procesos del tratamiento de agua, mediante la utilización de Biofiltros o Carrier´s, utilizados en los sistemas de tratamiento de aguas residuales diseñados para operar en reactores biológicos de lecho móvil o MBBR por sus siglas en ingles (Moving Bed Biological Reactor).",
                            "Este tratamiento consiste en la degradación de la materia orgánica por parte de bacterias aerobias y aumenta la eficiencia en el tratamiento."
                        ]
                    }
                ],
                selected: {},
                device: ''
            }
        },
        methods: {
            toggleServiceModal(service){
                if(service){
                    let selected = this.team.filter((value) => value.id === service);
                    this.selected = selected[0];
                } else {
                    this.selected = {
                        name: '',
                        description: ''
                    }
                }
                this.modal = !this.modal;
            }
        },
    }
</script>
<style>
    .service-img {
        width: 100%;
        height: auto;
    }

    .ar-icon-text {
        margin: 0;
        text-align: center;
        color: #092832;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        font-family: Oswald; 
        padding: 20px 0px;
    }   

    .service-element {
        margin: 25px;
        transition: all .2s;
        padding: 10px 10px 0px 10px;
    }

    .element-bg:hover > .service-element > .span-service {
        opacity: 1;
    }

    .element-bg {
        background-color: #f0f0f0;
        margin: 0px;
        border: 1px solid grey;
    }

    .service-section {
        border: 1px solid #e0e0e0;
        padding: 15px;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
    }
</style>