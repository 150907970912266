<template>
  <!-- Footer -->
  <mdb-footer color="arv-bg" class="font-small pt-4 mt-4">
    <mdb-container class="text-left mb-5 mt-5">
      <mdb-row>
        <mdb-col sm="6">
          <h5 class="title">Gracias a ti</h5>
          <p class="text-justify">Lograremos  ser referencia en México y Latinoaerica, sobre el manejo adecuado de recursos naturales que fomenten el equilibrio entre la economía y el medio ambiente.</p>
        </mdb-col>
        <mdb-col sm="6">
          <h6 class="text-uppercase font-weight-bold mb-3"><strong>Contacto</strong></h6>
          <!-- <p><i class="fa fa-home mr-3"></i> Calle Bonita 462, Morelia</p> -->
          <p><i class="fa fa-envelope mr-3"></i> contacto@arvoreglobalcorp.com</p>
          <p><i class="fa fa-phone mr-3"></i>(+52) 443 172 45 17 / (+52) 443 205 83 08</p>
          <p><b>Encuentranos en nuestras redes sociales:</b></p>
          <mdb-btn tag="a" target="_blank" href="https://www.facebook.com/ConsultoresArvore" size="md" floating class="btn-fb" icon="facebook-f" fab></mdb-btn>
          <mdb-btn tag="a" size="md" target="_blank" href="https://www.linkedin.com/company/arvoreenvironmental/" floating class="btn-tw" icon="linkedin" fab></mdb-btn>
          <mdb-btn tag="a" size="md" target="_blank" href="https://www.instagram.com/arvorecorp" floating class="btn-ins" icon="instagram" fab></mdb-btn>
        </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col>
        </mdb-col>
      </mdb-row>
    </mdb-container>
    <div class="footer-copyright text-center py-3">
      <mdb-container fluid>
        &copy; 2023 Copyright <a href="#"> Arvore Consultores </a>
      </mdb-container>
    </div>
  </mdb-footer>
  <!-- Footer -->
</template>
<script>
  import { mdbFooter, mdbContainer, mdbRow, mdbCol, mdbBtn } from 'mdbvue';
  export default {
    name: 'Footer',
    components: {
      mdbFooter,
      mdbContainer,
      mdbRow,
      mdbCol,
      mdbBtn
    }
  }
</script>
<style lang="scss">

    .btn-social-net {
      border-radius: 50%; 
      padding: 8px 12px; 
      color: white;
      margin: 5px; 
      outline: 0 !important; 
      &:hover {
        color: white;
      }
    }

    .btn-facebook {
      background-color: #3b5998; 
      border: 1px solid #3b5998;
    }

    .btn-twitter {
      background-color: #00ACEE; 
      border: 1px solid #00ACEE;
      padding: 8px 10px;
    }

    .btn-ins {
      background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d) !important; 
    }

    .btn-instagram {
      background:linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
      padding: 10px 12px;
    }
</style>